<template>
<div class="set-service-goods">
  <div class="service-goods-btn">
    <el-button type="primary" @click="addGoodsBtn"><i class="el-icon-plus"></i> 添加商品</el-button>
  </div>
  <div class="service-goods-table">
    <div class="goods-table-header">
      <div class="table-header-item" :style="styleFlex[0]"><span>商品信息</span></div>
      <div class="table-header-item" :style="styleFlex[1]"><span>销售价</span></div>
      <div class="table-header-item" :style="styleFlex[2]"><span>优惠券类型</span></div>
      <div class="table-header-item" :style="styleFlex[3]"><span>优惠券名称</span></div>
      <div class="table-header-item" :style="styleFlex[4]"><span>优惠券内容</span></div>
      <div class="table-header-item" :style="styleFlex[5]"><span>有效期</span></div>
      <div class="table-header-item" :style="styleFlex[6]"><span>操作</span></div>
    </div>
    <div class="goods-table-body">
      <div class="table-body-item" v-for="(item, index) in good_data">
        <div class="body-item-top">
          <div class="item-top-item" :style="styleFlex[0]">
            <el-select v-model="item.good_id" placeholder="请选择商品" :ref="`good_id_${index}`" @change="selectGoodsChange">
              <el-option
                  v-show="isSelectGoods(item.id)"
                  v-for="item in goodsList"
                  :key="item.id"
                  :label="item.good_title"
                  :value="item.id">
              </el-option>
              <el-option :value="0" label="暂无数据" v-if="goodsChoosableList.length === goodsList.length" :disabled="true"></el-option>
            </el-select>
          </div>
          <div class="item-top-item" :style="styleFlex[1]">
            <el-input-number :ref="`sale_${index}`" :controls="false" @change="setSale(index)" v-model="item.sale" :precision="2" :min="1" placeholder="销售价"></el-input-number>
          </div>
          <div class="item-top-item" :style="styleFlex[2]">
            <el-select :ref="`type_${index}`" v-model="item.defaultCoupon.type" placeholder="请选择优惠券类型">
              <el-option
                  v-for="item in discountsTypeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="item-top-item" :style="styleFlex[3]"><el-input :ref="`name_${index}`" v-model="item.defaultCoupon.name" placeholder="2-18个字"></el-input></div>
          <div class="item-top-item" :style="styleFlex[4]">
            满<el-input-number :ref="`price_man_${index}`" @change="cullSubtract(index)" style=";margin:0 10px" :controls="false" v-model="item.defaultCoupon.price_man" :precision="2" :min="1"></el-input-number>
            减<el-input-number :ref="`price_jian_${index}`" @change="cullSubtract(index)" style="margin-left: 10px" :controls="false" v-model="item.defaultCoupon.price_jian" :precision="2" :min="1"></el-input-number>
          </div>
          <div class="item-top-item" :style="styleFlex[5]">
            <el-date-picker
                @change="judgeTime(index)"
                :ref="`start_time_${index}`"
                style="margin-right: 10px"
                v-model="item.defaultCoupon.start_time"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="开始时间">
            </el-date-picker> ~
            <el-date-picker
                @change="judgeTime(index)"
                :ref="`end_time_${index}`"
                style="margin-left: 10px"
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="item.defaultCoupon.end_time"
                type="datetime"
                placeholder="结束时间">
            </el-date-picker>
          </div>
          <div class="item-top-item" :style="styleFlex[6]">
            <el-link type="success" @click="addDiscountBtn(index)">添加优惠券</el-link>
            <el-link type="danger" @click="deleteDiscountBtn(index)">删除</el-link>
          </div>
        </div>
        <div class="body-item-bottom" v-for="(itemCoupon, indexCoupon) in item.coupon">
          <div class="item-bottom-item" :style="styleFlex[0]"></div>
          <div class="item-bottom-item" :style="styleFlex[1]"></div>
          <div class="item-bottom-item" :style="styleFlex[2]">
            <el-select  v-model="itemCoupon.type" :ref="`type_${index}_${indexCoupon}`" placeholder="请选择优惠券类型">
              <el-option
                  v-for="item in discountsTypeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="item-bottom-item" :style="styleFlex[3]"><el-input :ref="`name_${index}_${indexCoupon}`" v-model="itemCoupon.name" placeholder="2-18个字"></el-input></div>
          <div class="item-bottom-item" :style="styleFlex[4]">
            满<el-input-number @change="cullSubtractTwo(index,indexCoupon)" :ref="`price_man_${index}_${indexCoupon}`" style=";margin:0 10px" :controls="false" v-model="itemCoupon.price_man" :precision="2" :min="1"></el-input-number>
            减<el-input-number @change="cullSubtractTwo(index,indexCoupon)" :ref="`price_jian_${index}_${indexCoupon}`" style="margin-left: 10px" :controls="false" v-model="itemCoupon.price_jian" :precision="2" :min="1"></el-input-number>
          </div>
          <div class="item-bottom-item" :style="styleFlex[5]">
            <el-date-picker
                @change="judgeTimeTwo(index,indexCoupon)"
                :ref="`start_time_${index}_${indexCoupon}`"
                style="margin-right: 10px"
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="itemCoupon.start_time"
                type="datetime"
                placeholder="开始时间">
            </el-date-picker> ~
            <el-date-picker
                @change="judgeTimeTwo(index,indexCoupon)"
                :ref="`end_time_${index}_${indexCoupon}`"
                style="margin-left: 10px"
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="itemCoupon.end_time"
                type="datetime"
                placeholder="结束时间">
            </el-date-picker>
          </div>
          <div class="item-bottom-item" :style="styleFlex[6]">
            <el-link type="danger" @click="deleteChild(index,indexCoupon)">删除</el-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {adminGoodListGood} from '@/utils/apis'
export default {
  name: "SetServiceGoods",
  props:['editData'],
  data(){
    return {
      good_data:[], //设置商品数据
      goodsList:[], // 商品列表
      goodsChoosableList:[],
      discountsTypeList:[
        {
          name:'商品优惠券',
          id:1
        },
        {
          name:'店铺优惠券',
          id:2
        }
      ],
      styleFlex:[
        {
          flex:0.8,
          marginRight:'18px'
        },
        {
          flex:0.42,
          marginRight:'20px'
        },
        {
          flex:0.7,
          marginRight:'20px'
        },
        {
          flex:0.8,
          marginRight:'30px'
        },
        {
          marginRight:'30px'
        },
        {
          flex:0.94,
          marginRight:'20px'
        },
        {
          flex:0.5
        },
      ],
    }
  },
  mounted() {
    if(this.$route.query.id){
      let time = setInterval(()=>{
        if(this.editData){
          clearInterval(time);
          this.getEditData()
        }
      },500)
    }
  },
  methods:{
    // 获取编辑数据
    getEditData(){
      let good_data = JSON.parse(JSON.stringify(this.editData.good_data));
      good_data.forEach(item=>{
        item.defaultCoupon = JSON.parse(JSON.stringify(item.coupon[0]));
        item.coupon.splice(0,1)
      })
      this.good_data = good_data
    },
    getAdminGoodListGood(id){
      let params = {
        category_id:id
      }
      adminGoodListGood(params).then((res)=>{
        this.goodsList = res.data;
      }).catch((err)=>{
        console.log('err',err)
      })
    },
    // 销售价不能大于市场价
    setSale(index){
      if(this.good_data[index].good_id){
        this.goodsList.forEach((item)=>{
          if(item.id === this.good_data[index].good_id){
            if(this.good_data[index].sale > Number(item.market_price)){
              this.$nextTick(()=>{
                this.$set(this.good_data[index], 'sale', Number(item.market_price))
              })
              this.$message('销售价不能大于商品市场价')
            }
          }
        })
      }
    },
    // 判断满减大于小于
    cullSubtract(index){
      if(this.good_data[index].defaultCoupon.price_man && this.good_data[index].defaultCoupon.price_jian){
        if(this.good_data[index].defaultCoupon.price_jian > this.good_data[index].defaultCoupon.price_man){
          this.$nextTick(()=>{
            this.$set(this.good_data[index].defaultCoupon,'price_jian', void 0);
            this.$message('满减优惠所减价格不能大于已满价格');
          })
        }
      }
    },
    cullSubtractTwo(index,indexCoupon){
      if(this.good_data[index].coupon[indexCoupon].price_man && this.good_data[index].coupon[indexCoupon].price_jian){
        if(this.good_data[index].coupon[indexCoupon].price_jian > this.good_data[index].coupon[indexCoupon].price_man){
          this.$nextTick(()=>{
            this.$set(this.good_data[index].coupon[indexCoupon],'price_jian', void 0);
            this.$message('满减优惠所减价格不能大于已满价格');
          })
        }
      }
    },
    // 限制时间结束不能大于开始
    judgeTime(index){
      if(this.good_data[index].defaultCoupon.start_time && this.good_data[index].defaultCoupon.end_time)
        if(this.good_data[index].defaultCoupon.start_time > this.good_data[index].defaultCoupon.end_time){
          this.$nextTick(()=>{
            this.$set(this.good_data[index].defaultCoupon, 'end_time', '');
            this.$message('有效期结束日期不能大于开始日期！');
          })
        }
    },
    judgeTimeTwo(index,indexCoupon){
      if(this.good_data[index].coupon[indexCoupon].start_time && this.good_data[index].coupon[indexCoupon].end_time){
        if(this.good_data[index].coupon[indexCoupon].start_time > this.good_data[index].coupon[indexCoupon].end_time){
          this.$nextTick(()=>{
            this.$set(this.good_data[index].coupon[indexCoupon], 'end_time', '');
            this.$message('有效期结束日期不能大于开始日期！');
          })
        }
      }
    },
    // 添加商品
    addGoodsBtn(){
      let obj = {
        good_id:'', // 选择的商品id
        sale:void 0, //销售价
        defaultCoupon:{
          type:'', // 优惠券类型 1商品优惠券 2店铺优惠券
          name:'', // 优惠券m名称
          price_man: void 0, // 满
          price_jian: void 0, // 减
          start_time: '', // 开始时间
          end_time: '', //结束时间
        },
        coupon:[]
      }
      this.good_data.push(obj)
    },
    // 添加优惠券
    addDiscountBtn(index){
      let obj = {
        type:'', // 优惠券类型 1商品优惠券 2店铺优惠券
        name:'', // 优惠券m名称
        price_man: void 0, // 满
        price_jian: void 0, // 减
        start_time: '', // 开始时间
        end_time: '', //结束时间
      }
      this.good_data[index].coupon.push(obj);
    },
    deleteDiscountBtn(index){
      this.$confirm(`是否删除该商品及相关优惠券，删除将无法恢复`, '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass:'green-message-box',
        type:'warning'
      }).then(()=>{
        this.good_data.splice(index,1);
          this.goodsChoosableList = []
          this.good_data.forEach(item=>{
            if(item.good_id){
              this.goodsChoosableList.push(item.good_id)
            }
          })
      }).catch(()=>{
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      })
    },
    deleteChild(index,indexCoupon){
      this.$confirm(`是否删除该优惠券，删除将无法恢复`, '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass:'green-message-box',
        type:'warning'
      }).then(()=>{
        this.good_data[index].coupon.splice(indexCoupon,1)
      }).catch(()=>{
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      })
    },
    // 筛选商品 去掉已经选择的
    selectGoodsChange(val){
      this.goodsChoosableList = []
      this.good_data.forEach(item=>{
        if(item.good_id){
          this.goodsChoosableList.push(item.good_id)
        }
      })
    },
    isSelectGoods(id){
      if(this.goodsChoosableList.includes(id)){
        return false
      } else {
        return true
      }
    },
    // 提交验证
    confirmForm(){
      if(this.good_data.length){
        let isErrNum = 0;
        let arr = ['type','name', 'price_man', 'price_jian', 'start_time', 'end_time']
        this.good_data.forEach((item,index)=>{
          if(!item.good_id){
            this.$refs[`good_id_${index}`][0].$el.classList.add('isError');
            isErrNum = 1;
          } else {
            this.$refs[`good_id_${index}`][0].$el.classList.remove('isError');
          }
          if(!item.sale){
            this.$refs[`sale_${index}`][0].$el.classList.add('isError');
            isErrNum = 1;
          } else {
            this.$refs[`sale_${index}`][0].$el.classList.remove('isError');
          }
          arr.forEach(val=>{
            if(!item.defaultCoupon[val]){
              this.$refs[`${val}_${index}`][0].$el.classList.add('isError');
              isErrNum = 1;
            } else {
              this.$refs[`${val}_${index}`][0].$el.classList.remove('isError');
            }
            if(item.coupon.length){
              item.coupon.forEach((childrenVal, childrenIndex)=>{
                if(!childrenVal[val]){
                  this.$refs[`${val}_${index}_${childrenIndex}`][0].$el.classList.add('isError');
                  isErrNum = 1;
                } else {
                  this.$refs[`${val}_${index}_${childrenIndex}`][0].$el.classList.remove('isError');
                }
              })
            }
          })
        })
        if(isErrNum === 0){
          let good_data = JSON.parse(JSON.stringify(this.good_data));
          good_data.forEach(item=>{
            item.coupon.unshift(item.defaultCoupon);
            delete item.defaultCoupon;
            this.goodsList.forEach(item2=>{
              if(item.good_id === item2.id){
                item.good_name = item2.good_title
              }
            })
          })
          this.$emit('stepActiveAdd',good_data)
        } else {
          this.$message.warning('每项为必填！')
        }
      } else {
        this.$message.warning('请先添加至少一个商品')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.set-service-goods{
  margin-top: 20px;
  .service-goods-btn{
    text-align: right;
  }
  .service-goods-table{
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    .goods-table-header{
      padding: 0 30px;
      display: flex;
      background: #EEEEEE;
      height: 60px;
      line-height: 60px;
      box-sizing: border-box;
      span{
        color: #666666;
        font-size: 16px;
      }
      .table-header-item{
        text-align: center;
        flex: 1;
        width: 1%;
        &:first-of-type{
          text-align: left;
        }
      }
    }
    .goods-table-body{
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      .table-body-item{
        display: flex;
        flex-direction: column;
        .body-item-top, .body-item-bottom{
          padding: 0 30px;
          box-sizing: border-box;
          width: 100%;
          display: flex;
          border-bottom: 1px solid #EEEEEE;
          .item-top-item, .item-bottom-item{
            width: 1%;
            flex: 1;
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            ::v-deep .el-date-editor{
              .el-input__inner{
                padding: 0 10px;
              }
              .el-input__prefix{
                display: none;
              }
            }
            //&.item-bottom-item:last-of-type{
            //  justify-content: flex-end;
            //  .el-link{
            //    margin-right: 6px;
            //  }
            //}
          }
        }
      }
    }
  }
}
</style>